// Load the APS JavaScript Library and adding eslint for comments
!(function (a9, a, p, s, t, A, g) { // eslint-disable-line
  if (a[a9]) return;
  function q(c, r) {
    a[a9]._Q.push([c, r]); // eslint-disable-line
  }
  a[a9] = { // eslint-disable-line
    init: function () { // eslint-disable-line
      q('i', arguments); // eslint-disable-line
    },
    fetchBids: function () { // eslint-disable-line
      q('f', arguments); // eslint-disable-line
    },
    setDisplayBids: function () {}, // eslint-disable-line
    targetingKeys: function () { // eslint-disable-line
      return [];
    },
    _Q: [],
  };
  A = p.createElement(s); // eslint-disable-line
  A.async = !0; // eslint-disable-line
  A.src = t; // eslint-disable-line
  g = p.getElementsByTagName(s)[0]; // eslint-disable-line
  g.parentNode.insertBefore(A, g); // phpcs:ignore
})('apstag', window, document, 'script', '//c.amazon-adsystem.com/aax2/apstag.js');

// Initialize the Library
apstag.init({ // eslint-disable-line
  pubID: window.IDG.APS.pubID,
  adServer: 'googletag',
  videoAdServer: 'DFP',
  bidTimeout: 2e3,
  deals: true,
});
